import React, { useEffect, useState } from "react";
import { Modal, message } from "antd";
import { MainTable } from "components";
import { instance } from "utils/axios";

function CustomerHistory({ open=false, onClose, customerId }) {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (customerId && open) {
      fetchHistoryData(customerId);
    }
  }, [customerId, open]);

  const fetchHistoryData = (id) => {
    setLoading(true);
    instance({
      method: "get",
      url: `/customer/history/${id}`,
    })
      .then((res) => {
        setHistoryData(res.data);
      })
      .catch(() => {
        message.error("Түүхийг авахад алдаа гарлаа");
      })
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      title: "Огноо",
      dataIndex: "currentDateTime",
      width: 120,
      key: "currentDateTime",
      render: (text, row) => (
        <span>
          {text??row.currentDate}
        </span>
      ),
    },
    {
      title: "Зовиур",
      dataIndex: "painful",
      key: "painful",
      width: 200,
    },
    {
      title: "Төхөөрөмж",
      dataIndex: "serviceTypeName",
      key: "serviceTypeName",
      width: 110,
      render: (text, row) => (
        <span>
          {text} {text === "CT" ? "/" + row.device + "/" : ""}
        </span>
      ),
    },
    {
      title: "Шинжлүүлэх эрхтэн",
      dataIndex: "serviceNames",
      width: 150,
      key: "serviceNames",
      render: (text, row) => (
        <span>{`${text}${row.isCe ? "+CE" : ""}${
          row.isAns ? "+ANS" : ""
        }`}</span>
      ),
    },
    {
      title: "Эмнэлэг",
      dataIndex: "hospitalName",
      key: "hospitalName",
    },
    {
      title: "Техникч",
      dataIndex: "technicalUser",
      key: "technicalUser",
    },
    {
      title: "Эмч",
      dataIndex: "doctor",
      key: "doctor",
      width: 180,
    },
    {
      title: "Зөвлөх эмч",
      dataIndex: "doctor2",
      width: 180,
      key: "doctor2",
      render: (text) => text || "N/A",
    },
    {
      title: "Тодосгогч бодис",
      dataIndex: "ceitem",
      key: "ceitem",
      render: (text) => text || "N/A",
    },
  ];

  return (
    <Modal
      title="Үйлчлүүлэгчийн түүх"
      open={open}
      onOk={onClose}
      onCancel={onClose}
      width={1200}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <MainTable
        dataTable={historyData}
        columns={columns}
        loading={loading}
        title=""
      />
    </Modal>
  );
}

export default CustomerHistory;
