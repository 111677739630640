import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Tag,
  Modal,
  Form as AntForm,
  Input,
  message,
  Select,
} from "antd";
import { AuthContext } from "contexts";
import { instance } from "utils/axios"; // Axios-г зөв тохируулсан байна
import { Form, MainTable, CompanySelect } from "components";
import { BiEdit, BiTrash } from "react-icons/bi";
import fields from "./fields";
export default function FeedbackComponent() {
     const [modal, contextHolder] = Modal.useModal();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const [form] = AntForm.useForm();
  const { userInfo } = useContext(AuthContext);
    const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchFeedback();
  }, []);

  const fetchFeedback = () => {
    setLoading(true);
    instance
      .get("/feedbacks")
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        message.error("Санал хүсэлтийг авч чадсангүй");
        setLoading(false);
      });
  };

  const handleOpenModal = (feedback = null) => {
    setEditData(feedback);
    setOpen(true);
    form.setFieldsValue(feedback);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };


  const handleSubmit = (values) => {
    setLoading(true);

    // Create FormData object to handle both text and file data
    const formData = new FormData();

    // Append all form fields to FormData
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key] || "");
    });

    // Check if an image file is selected and append it to FormData
    if (values.image?.file) {
      formData.append("image", values.image.file);
    }

    const requestConfig = {
      method: editData?.id ? "put" : "post",
      url: editData?.id ? `/feedback/${editData.id}` : "/feedback",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data", // Ensure proper content type for file upload
      },
    };

    instance(requestConfig)
      .then(() => {
        handleCloseModal();
        fetchFeedback(); // Refresh feedback list
      })
      .catch(() => {
        message.error("Илгээх явцад алдаа гарлаа");
      })
      .finally(() => setLoading(false));
  };


  const handleDelete = (id) => {
    instance
      .delete(`/feedback/${id}`)
      .then(() => {
        message.success("Санал хүсэлт амжилттай устгагдлаа");
        fetchFeedback();
      })
      .catch(() => {
        message.error("Устгах явцад алдаа гарлаа");
      });
  };
  const columns = [
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Утас",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Төрөл",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Чухал",
      dataIndex: "priority",
      key: "priority",
    },
    {
      title: "Санал хүсэлт",
      dataIndex: "feedback",
      key: "feedback",
    },
    {
      title: "Зураг",
      dataIndex: "fileUrl",
      key: "fileUrl",
      render: (text) =>
        text ? (
          <a
            href={`${process.env.REACT_APP_CDN_URL}${text}`}
             target="_blank"
            rel="noopener noreferrer"
          >
            Зураг
          </a>
        ) : (
          <></>
        ),
    },
    {
      title: "Бүртгэсэн",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Шийдсэн",
      dataIndex: "resolverId",
      key: "resolverId",
    },
    {
      title: "Шийдсэн огноо",
      dataIndex: "resolveDate",
      key: "resolveDate",
      render: (date) => (date ? new Date(date).toLocaleDateString() : "N/A"),
    },
    {
      title: "Тэмдэглэл",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      key: "status",
      render: (value) => (
        <Tag color={value == "Шийдсэн" ? "success" : "red"}>{value}</Tag>
      ),
    },
    {
      title: "Үйлдэл",
      key: "actions",
      render: (_, record) =>
        record.status == "Шийдсэн" ? (
          <></>
        ) : (
          <div className="flex items-center gap-5">
            <Button size="small" onClick={() => handleResolve(record)}>
              <div className="flex items-center gap-2">Шийдэх</div>
            </Button>
            {/* <Button onClick={() => handleOpenModal(record)} icon={<BiEdit />} />
          <Button onClick={() => handleDelete(record.id)} icon={<BiTrash />} /> */}
          </div>
        ),
    },
  ];
const handleResolve = (feedback) => {
    
  modal.confirm({
    title: "Санал хүсэлтийг шийдэх",
    content: (
      <AntForm form={form} layout="vertical">
        <AntForm.Item name="note" label="Шийдвэрлэх тайлбар">
          <Input.TextArea
            rows={4}
            placeholder="Шийдвэрлэх тайлбараа оруулна уу"
          />
        </AntForm.Item>
      </AntForm>
    ),
    onOk: () => {
      form
        .validateFields()
        .then((values) => {
          instance
            .post(`/feedback/resolve`, {
              ...values,
              id: feedback.id,

              status: "Шийдсэн",
            })
            .then(() => {
              message.success("Санал хүсэлт амжилттай шийдэгдлээ");
              fetchFeedback();
              form.resetFields();
            })
            .catch(() => {
              message.error("Шийдвэрлэх явцад алдаа гарлаа");
            });
        })
        .catch(() => message.error("Тайлбараа оруулна уу"));
    },
    onCancel: () => form.resetFields(),
  });
};


  return (
    <div>
      <MainTable
        dataTable={data}
        columns={columns}
        // setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={true}
        title={"Санал хүсэлт"}
        handleAdd={() => {
          handleOpenModal({ type: "Гомдол", priority: "Энгийн" });
        }}
      />
      <Modal
        title={
          editData ? "Санал хүсэлтийн мэдээлэл засах" : "Шинэ Санал хүсэлт"
        }
        open={open}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields()}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
      {contextHolder}
    </div>
  );
}
