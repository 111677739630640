import {
  BiUserCheck,
  BiIdCard,
  BiCog,
  BiGrid,
  BiBuilding,
  BiHorizontalLeft,
  BiHorizontalRight,
  BiCalendarPlus,
  BiDialpad,
  BiUserCircle,
  BiTransferAlt,
  BiBarChartAlt,
  BiBed,
  BiHealth,
  BiKnife,
  BiDialpadAlt,
  BiCalendar,
  BiDoorOpen,
  BiChip,
  BiPhone,
  BiFolder,
} from "react-icons/bi";
import { DashboardOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AuthContext } from "contexts";

export default [
  {
    label: <Link to="/">Хянах самбар</Link>,
    icon: <DashboardOutlined size={16} className="inline text-[#F19920]" />,
    key: "dashboard",
    role: ["Админ", "CEO", "Гишүүн"],
    hospital: [1, 2],
  },
  {
    label: <Link to="/callreport">Дуудлага</Link>,
    icon: <BiPhone size={16} className="inline text-[#F19920]" />,
    key: "callreport",
    role: ["Админ", "CEO"],
    hospital: [1],
  },
  {
    label: <Link to="/inpatient-dashboard">Хэвтэн хянах самбар</Link>,
    icon: <DashboardOutlined size={16} className="inline text-[#F19920]" />,
    key: "inpatientdashboard",
    role: ["Админ", "CEO"],
    hospital: [1, 2],
  },
  {
    label: <Link to="/prebooking">Цаг захиалга</Link>,
    icon: <BiCalendarPlus size={16} className="inline text-[#F19920]" />,
    key: "prebooking",
    role: ["Ресейпшн", "Техникч", "Админ", "CEO", "Менежер", "Нягтлан"],
    hospital: [1, 2],
  },
  {
    label: <Link to="/booking">Шинжилгээ</Link>,
    icon: <BiGrid size={16} className="inline text-[#F19920]" />,
    key: "booking",
    role: [
      "Ресейпшн",
      "Эмч",
      "Нягтлан",
      "Техникч",
      "Админ",
      "CEO",
      "Гишүүн",
      "Эрхлэгч",
      "Менежер",
      "Нягтлан",
    ],
    hospital: [1, 2],
  },
  {
    label: <Link to="/pet-booking">PET Шинжилгээ</Link>,
    icon: <BiGrid size={16} className="inline text-[#F19920]" />,
    key: "pet-booking",
    role: [
      "Ресейпшн",
      "Эмч",
      "Нягтлан",
      "Техникч",
      "Админ",
      "Эрхлэгч",
      "Менежер",
      "Physician",
    ],
    hospital: [2],
  },
  {
    label: <Link to="/shift-schedule">Техникчийн хуваарь</Link>,
    icon: <BiCalendar size={16} className="inline text-[#F19920]" />,
    key: "shift_schedlue",
    role: ["Админ", "Менежер", "Нягтлан"],
    hospital: [1, 2],
  },
  {
    label: <Link to="/report">Эмчийн тайлан</Link>,
    icon: <BiBarChartAlt size={16} className="inline text-[#F19920]" />,
    key: "report",
    role: ["Админ", "CEO", "Менежер", "Нягтлан", "Эмч", "Эрхлэгч"],
    hospital: [1, 2],
  },

  {
    label: <Link to="/stats">Үзүүлэлт</Link>,
    icon: <BiBarChartAlt size={16} className="inline text-[#F19920]" />,
    key: "stats",
    role: [
      "Ресейпшн",
      "Эмч",
      "Техникч",
      "Админ",
      "CEO",
      "Гишүүн",
      "Эрхлэгч",
      "Менежер",
      "Physician",
      "Нягтлан",
    ],
    hospital: [1, 2],
  },
  {
    label: <Link to="/feedback">Санал хүсэлт</Link>,
    icon: <BiGrid size={16} className="inline text-[#F19920]" />,
    key: "feedback",
    role: [
      "Ресейпшн",
      "Эмч",
      "Нягтлан",
      "Техникч",
      "Админ",
      "Эрхлэгч",
      "Менежер",
      "Physician",
    ],
    hospital: [1],
  },
  {
    label: <Link to="/customer">Үйлчлүүлэгч</Link>,
    key: "customer",
    role: ["Админ", "CEO", "Менежер", "Ресейпшн", "Нягтлан", "Техникч"],
    icon: <BiUserCheck size={16} className="inline text-[#F19920]" />,
    hospital: [1, 2],
  },
  {
    label: <Link to="/surgery-type">Мэс заслын нэр</Link>,
    icon: <BiHealth size={16} className="inline text-[#F19920]" />,
    key: "surgery-type",
    role: ["Админ", "CEO", "Эрхлэгч", "Менежер", "Хэвтэн"],
    hospital: [2],
  },
  {
    label: <Link to="/surgery">Мэс заслын хуваарь</Link>,
    icon: <BiKnife size={16} className="inline text-[#F19920]" />,
    key: "surgery",
    role: ["Админ", "CEO", "Менежер", "Хэвтэн", "Ресейпшн", "Нягтлан"],
    hospital: [2],
  },
  {
    role: ["Админ", "Менежер", "Хэвтэн", "Нягтлан"],
    hospital: [2],
    label: <Link to="/inpatient">Хэвтэн эмчлүүлэх</Link>,
    icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
    children: [
      {
        label: <Link to="/inpatient">Хэвтэн эмчлүүлэх</Link>,
        key: "inpatient",
        role: ["Админ", "Менежер", "Нягтлан"],
        hospital: [2],
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
      },
      {
        label: <Link to="/ward">Тасаг</Link>,
        key: "ward",
        role: ["Админ", "Менежер", "Хэвтэн", "Нягтлан"],
        hospital: [2],
        icon: <BiChip size={16} className="inline text-[#F19920]" />,
      },
      {
        label: <Link to="/room">Өрөө</Link>,
        key: "room",
        role: ["Админ", "Менежер", "Хэвтэн", "Нягтлан"],
        hospital: [2],
        icon: <BiDoorOpen size={16} className="inline text-[#F19920]" />,
      },
      {
        label: <Link to="/room-bed">Ор</Link>,
        key: "room_bed",
        role: ["Админ", "Менежер", "Хэвтэн", "Нягтлан"],
        hospital: [2],
        icon: <BiBed size={16} className="inline text-[#F19920]" />,
      },
      {
        label: <Link to="/document">Бичиг баримт</Link>,
        key: "document",
        role: ["Админ", "Менежер", "Хэвтэн"],
        hospital: [2],
        icon: <BiFolder size={16} className="inline text-[#F19920]" />,
      },
    ],
  },
  {
    label: <Link to="/incomeandexpense">Орлого Зарлага</Link>,
    icon: <BiTransferAlt size={16} className="inline text-[#F19920]" />,
    key: "incomeandexpense",
    role: ["Админ", "Менежер"],
    hospital: [1],
  },
  {
    label: "Тохиргоо",
    role: ["Админ", "Менежер"],
    icon: <BiCog size={16} className="inline text-[#F19920]" />,
    hospital: [1, 2],
    children: [
      {
        label: <Link to="/employee">Ажилчид</Link>,
        key: "employee",
        role: ["Админ", "Менежер"],
        hospital: [1, 2],
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
      },
      {
        label: <Link to="/caller">Дуудлана</Link>,
        key: "caller",
        role: ["Админ", "Менежер"],
        icon: <BiDialpadAlt size={16} className="inline text-[#F19920]" />,
        hospital: [1],
      },
      {
        label: <Link to="/services">Үйлчилгээ</Link>,
        key: "services",
        role: ["Админ", "Менежер"],
        icon: <BiDialpadAlt size={16} className="inline text-[#F19920]" />,
        hospital: [1, 2],
      },
      {
        label: <Link to="/servicetype">Төхөөрөмж</Link>,
        key: "servicetype",
        role: ["Админ", "Менежер"],
        icon: <BiDialpad size={16} className="inline text-[#F19920]" />,
        hospital: [1, 2],
      },
      {
        label: <Link to="/devices">Аппарат</Link>,
        key: "devices",
        role: ["Админ", "Менежер"],
        icon: <BiDialpadAlt size={16} className="inline text-[#F19920]" />,
        hospital: [1, 2],
      },
      {
        label: <Link to="/ceitem">Бодис</Link>,
        key: "ceitems",
        role: ["Админ", "Менежер"],
        icon: <BiBuilding size={16} className="inline text-[#F19920]" />,
        hospital: [1, 2],
      },

      {
        label: <Link to="/organization">Компаний мэдээлэл</Link>,
        key: "organization",
        role: ["Админ"],
        hospital: [1, 2],
        icon: <BiBuilding size={16} className="inline text-[#F19920]" />,
      },

      {
        label: "Орлого Зарлага",
        key: "expensetype1",
        role: ["Админ", "Менежер"],
        hospital: [1],
        icon: <BiTransferAlt size={16} className="inline text-[#F19920]" />,
        children: [
          {
            label: <Link to="/incometype">Орлогын төрөл</Link>,
            key: "incometype",
            role: ["Админ", "Менежер"],
            hospital: [1],
            icon: (
              <BiHorizontalRight size={16} className="inline text-[#F19920]" />
            ),
          },
          {
            label: <Link to="/expensetype">Зарлагын төрөл</Link>,
            key: "expensetype",
            role: ["Админ", "Менежер"],
            hospital: [1],
            icon: (
              <BiHorizontalLeft size={16} className="inline text-[#F19920]" />
            ),
          },
          {
            label: <Link to="/expense">Дэд Зарлага</Link>,
            key: "expense",
            role: ["Админ", "Менежер"],
            hospital: [1],
            icon: (
              <BiHorizontalLeft size={16} className="inline text-[#F19920]" />
            ),
          },
        ],
      },
    ],
  },
];
