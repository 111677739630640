import React, { useContext, useEffect, useState } from "react";
import { instance } from "utils/axios";

import { DatePicker, TimePicker, Select, Button, Table, Tag } from "antd";
import { DoctorAvg } from "modules/admin";
import TechnicalCount from "modules/admin/dashboard/Technical";
import ReceptionCount from "./Reception";
import { AuthContext } from "contexts";
import moment from "moment";
import { CompanySelect, MainTable } from "components";

function Stats() {
  const authContext = useContext(AuthContext);
  const { action } = authContext;
  const hospitalId = authContext.state.userInfo.profile.hospitalId;
  const [selectedHostpital, setSelectedHospital] = useState(hospitalId);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [date, setDate] = useState(moment());

  const [serviceType, setServiceType] = useState(-1);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf("day"));
  const [startTime, setStartTime] = useState(moment("08:00", "HH:mm"));
  const [endTime, setEndTime] = useState(moment("08:00", "HH:mm"));
  const [endDate, setEndDate] = useState(moment().endOf("day").add(1));

  const role = authContext.state.userInfo.profile.role;
  useEffect(() => {
    action.handleSidebarKey("stats");
      if (role != "Physician") {
        instance({
          method: "get",
          url: `/servicetype`,
        })
          .then((res) => {
            let tmp = [];
            if (res.data.length != 1) {
              tmp.push({ label: "Бүгд", value: -1 });
              setServiceType(-1);
            } else setServiceType(res.data[0].id);

            res.data.map((item) => {
              tmp.push({ label: item.name, value: item.id, text: item.name });
            });

            setServiceTypes(tmp);
          })
          .catch((err) => {});
      } else {
       
         setServiceTypes([{ label: "PET/CT", value: 3, text: "PET/CT" }]);
         setServiceType(3)
      }
  }, []);
  useEffect(() => {
   
    fetchData();
  }, [startDate, endDate, serviceType, selectedHostpital, startTime,endTime]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/stats/role?date=${moment(startDate).format("YYYY-MM-DD")}T${moment(
        startTime
      ).format("HH:mm:ss")}&enddate=${moment(endDate).format(
        "YYYY-MM-DD"
      )}T${moment(endTime).format(
        "HH:mm:ss"
      )}&servicetype=${serviceType}&hospital=${selectedHostpital}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  return (
    <div className="flex flex-col px-4 pb-4">
      {/* <div className="px-1  md:px-5"> */}
      <div className="flex gap-5 mb-3 ">
        <CompanySelect
          value={selectedHostpital}
          onChange={(e) => {
            setSelectedHospital(e);
          }}
        />

        <Select
          value={serviceType}
          options={serviceTypes}
          onChange={(e) => {
            setServiceType(e);
          }}
        />
        <Button
          // type="primary"
          // size="small"
          className=" flex items-center"
          onClick={() => {
            setStartDate(moment().startOf("month"));
            setEndDate(moment().endOf("month").add(1));
            setStartTime(moment("08:00", "HH:mm:ss"));
            setEndTime(moment("08:00:00", "HH:mm:ss"));
          }}
        >
          {/* <ReloadOutlined />  */}
          Сар
        </Button>
        <DatePicker value={startDate} onChange={(e) => setStartDate(e)} />
        <TimePicker
          className="w-[100px]"
          // className="mb-0 w-[100px]"
          format="HH:mm"
          value={startTime}
          onChange={(e) => {
            setStartTime(e);
          }}
          changeOnBlur
        />

        <DatePicker value={endDate} onChange={(e) => setEndDate(e)} />
        <TimePicker
          // className="mb-0 w-[100px]"
          className=" w-[100px]"
          format="HH:mm"
          value={endTime}
          onChange={(e) => {
            setEndTime(e);
          }}
          changeOnBlur
        />
      </div>

      <div className="grid grid-cols-12 gap-5 mt-5"></div>

      <div className="grid grid-cols-12 gap-5 mt-5">
        {role == "Админ" ||
        role == "CEO" ||
        role == "Гишүүн" ||
        role == "Эмч" ||
        role == "Physician" ||
        role == "Эрхлэгч" ||
        role == "Нягтлан" ||
        role == "Менежер" ? (
          <DoctorAvg
            data={data?.monthDoctorAvgData}
            className="col-span-12 md:col-span-12 "
          />
        ) : (
          <div></div>
        )}
        {role == "Админ" ||
        role == "CEO" ||
        role == "Гишүүн" ||
        role == "Техникч" ||
        role == "Physician" ||
        role == "Менежер" ||
        role == "Нягтлан" ? (
          <TechnicalCount
            data={data?.monthTechnicalCountData}
            className="col-span-12 md:col-span-6 "
          />
        ) : (
          <div></div>
        )}
        {role == "Админ" ||
        role == "CEO" ||
        role == "Physician" ||
        role == "Менежер" ||
        role == "Нягтлан" ? (
          <div
            className={` bg-white rounded-lg shadow p-3 flex flex-col col-span-12 md:col-span-6 `}
          >
            <div className="flex gap-5">
              <div className="border rounded-lg overflow-hidden flex-1">
                <MainTable
                  dataTable={data?.monthPhysicianCountData}
                  columns={[
                    {
                      title: "Нэр",
                      dataIndex: "firstname",
                      key: "firstname",
                    },
                    {
                      title: "PET Тоо",
                      dataIndex: "cnt",
                      key: "cnt",
                      render: (text, row) => (
                        <div>{Intl.NumberFormat().format(text)} </div>
                      ),
                      sorter: (a, b) => a.cnt - b.cnt,
                    },
                  ]}
                  title={"Физикчийн тоо"}
                  summary={(pageData) => {
                    let total = 0;
                    if (data?.monthPhysicianCountData)
                      data?.monthPhysicianCountData?.forEach(({ cnt }) => {
                        total += cnt;
                      });

                    return (
                      <>
                        <Table.Summary.Row rowClassName={"table-row-dark"}>
                          <Table.Summary.Cell>
                            {" "}
                            <Tag color="success">Total</Tag>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <div>{total}</div>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {role == "Админ" ||
        role == "CEO" ||
        role == "Ресейпшн" ||
        role == "Менежер" ? (
          <ReceptionCount
            data={data?.monthReceptionCountData}
            className="col-span-12 md:col-span-6 "
          />
        ) : (
          <div></div>
        )}
      </div>
      {/* </div> */}
    </div>
  );
}

export default Stats;
