import React, { useState, useEffect, useContext } from "react";
import { AiOutlineUser } from "react-icons/ai";
import {
  Button,
  Modal,
  Form as AntForm,
  Menu,
  Badge,
  List,
  notification,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "contexts";
import { Form } from "components";
import ls from "utils/ls";
import { MenuOutlined, CloseOutlined, BellOutlined } from "@ant-design/icons";
import menu from "../../menu";
import { initializeWebSocket, sendMessage } from "utils/websocket";
import { instance } from "utils/axios";

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenNotifician, setIsOpenNotifician] = useState(false);
  const { state, action } = useContext(AuthContext);
  const [currentKey, setCurrentKey] = useState("");
  const [roleMenu, setRoleMenu] = useState([]);
  const [form] = AntForm.useForm();
  const authContext = useContext(AuthContext);

  const navigate = useNavigate();
  const [count, setCnt] = useState(0);
  const [api, contextHolder] = notification.useNotification();
  const userId = authContext.state.userInfo.profile.id;

  const openNotification = ({ title, message }) => {
    api.open({
      message: title,
      description: message,
    });
  };

  useEffect(() => {
    setCurrentKey(ls.get("currentMenuKey"));
    let tmp = [];
    menu.forEach((item) => {
      if (
        item?.role.includes(state.userInfo?.profile.role) &&
          (item?.hospital.includes(state.userInfo?.profile.hospitalId) ||
           item?.hospital.includes(state.userInfo?.profile.hospitalSecondId))
      ) {
        tmp.push(item);
      }
    });

    setRoleMenu(tmp);

//     initializeWebSocket(userId, (data) => {
//       // console.log(data)
//       openNotification({
//         title: data.title,
//         message: data.message,
//       });
//       setCnt((prevCount) => prevCount + 1);
//     });

//     getCount();
  }, [userId]);

  const getCount = () => {
    instance({
      method: "get",
      url: `/notification/count`,
    })
      .then((res) => {
        setCnt(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleLogout = () => {
    authContext.action.logout();
    ls.remove("userInfo");
    navigate("/login");
  };

  const fields = [
    {
      label: "Хуучин нууц үг",
      name: "oldpassword",
      type: "password",
      className: "col-span-12 mb-0",
      rules: [{ required: true, message: "Хуучин нууц үг оруулна уу" }],
    },
    {
      label: "Шинэ нууц үг",
      name: "newpassword",
      type: "password",
      className: "col-span-12 mb-0",
      rules: [{ required: true, message: "Шинэ нууц үг оруулна уу" }],
    },
    {
      label: "Шинэ нууц үг давтах",
      name: "confirmpassword",
      type: "password",
      className: "col-span-12 mb-0",
      rules: [{ required: true, message: "Шинэ нууц үг давтан оруулна уу" }],
    },
  ];

  const handleSubmitChangePassword = (values) => {
    setLoading(true);
    instance({
      method: "put",
      url: "changepassword",
      data: {
        oldpassword: values.oldpassword,
        newpassword: values.newpassword,
      },
    })
      .then(() => {
        setOpenModal(false);
      })
      .catch((err) => {
        console.error(err);
      })
      .then(() => setLoading(false));
  };

  const getNotifications = () => {
    instance({
      method: "get",
      url: `/notification/list`,
    })
      .then((res) => {
        setData(res.data);
        const ids = res.data.map((item) => item.id);
        setIsOpenNotifician(true);
        instance({
          method: "put",
          url: `/notification/update`,
          data: ids,
        });
      })
      .catch((err) => {
        console.error(err);
        setIsOpenNotifician(false);
      })
      .finally(() => {
        getCount();
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <header className="px-5 md:px-10">
        <Badge count={count} offset={[-10, 10]}>
          <Button
            shape="circle"
            className="flex items-center border border-gray-200 justify-center bg-white shadow-lg rounded-full h-12 w-12 mr-2"
            type="text"
            onClick={() => {
              if (isOpenNotifician) setIsOpenNotifician(false);
              else {
                getNotifications();
              }
            }}
          >
            <BellOutlined className="text-3xl" />
          </Button>
          {isOpenNotifician && (
            <div
              className="flex flex-col absolute right-0 bottom-0 rounded z-20 bg-white shadow-xl translate-y-full whitespace-nowrap"
              tabIndex={-1}
            >
              <List
                bordered
                dataSource={data}
                renderItem={(item) => (
                  <List.Item>
                    <div>
                      <b>
                        <a>{item.title}</a>
                      </b>
                      <br />
                      {item.message}
                    </div>
                  </List.Item>
                )}
              />
            </div>
          )}
        </Badge>
        <div className="border border-gray-200 flex flex-row justify-center bg-white shadow-lg rounded-full">
          <div
            className="px-10 py-2 relative flex items-center justify-center gap-4"
            onMouseOver={() => setDropdownOpen(true)}
            onMouseLeave={() => setDropdownOpen(false)}
          >
            <div className="text-right">
              <div className="text-black text-base">
                {authContext.state.userInfo?.profile?.lastname[0]}
                {". "}
                {authContext.state.userInfo?.profile?.firstname}
              </div>
              <div className="text-mute text-xs">
                {authContext.state.userInfo?.profile.role}
              </div>
            </div>
            <AiOutlineUser className="text-3xl" />
            {dropdownOpen && (
              <div
                className="flex flex-col absolute right-0 bottom-0 py-2 rounded z-20 bg-white shadow-xl translate-y-full whitespace-nowrap"
                tabIndex={-1}
              >
                <Link
                  className="text-graytext p-4 hover:text-primary transition-all border-b last:border-b-0"
                  onClick={() => setOpenModal(true)}
                >
                  Нууц үг солих
                </Link>
                <Link
                  className="text-graytext p-4 hover:text-primary transition-all border-b last:border-b-0"
                  onClick={handleLogout}
                >
                  Гарах
                </Link>
              </div>
            )}

            <Button
              onClick={() => setIsOpenMenu(!isOpenMenu)}
              className="cursor-pointer z-50 outline-none block md:hidden"
            >
              {isOpenMenu ? <CloseOutlined /> : <MenuOutlined />}
            </Button>
          </div>
        </div>
      </header>
      <Modal
        title={<div className="text-lg">Нууц үг солих</div>}
        open={openModal}
        onCancel={handleCloseModal}
        width={700}
        destroyOnClose
        footer={false}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmitChangePassword}
          className="gap-4"
        />
        <div className="flex justify-end mt-4">
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Хадгалах
          </Button>
        </div>
      </Modal>
      <div
        className={`fixed pb-12 w-full h-full flex flex-row z-[45] top-[100px] transition-all ${
          isOpenMenu ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="w-1/4" onClick={() => setIsOpenMenu(!isOpenMenu)}></div>
        <div className="w-3/4 overflow-y-scroll bg-gradient-to-r from-[#051212] to-[#0d292b]">
          <Menu
            onClick={() => setIsOpenMenu(!isOpenMenu)}
            defaultSelectedKeys={["menu-item-0"]}
            mode={"inline"}
            theme={"dark"}
            key={"menu"}
            inlineCollapsed={false}
            items={roleMenu}
          />
        </div>
      </div>
      {contextHolder}
    </>
  );
};

export default Header;
