import { Form, Input, Select } from "antd";

export default () => {
  return [
    {
      label: "Нэр",
      name: "name",
      className: "col-span-12 md:col-span-6 mb-0",
      // rules: [{ required: true, message: "Нэрээ оруулна уу!" }],
      inputComponent: Input,
    },
    {
      label: "Утасны дугаар",
      name: "mobile",
      className: "col-span-12 md:col-span-6 mb-0",

      // rules: [{ required: true, message: "Утасны дугаараа оруулна уу!" }],
    },
    {
      label: "Төрөл",
      name: "type",
      className: "col-span-12 md:col-span-6 mb-0",
      rules: [{ required: true, message: "Саналын төрлийг сонгоно уу!" }],
      type: "select",
      inputProps: {
        className: "w-full",
        options: [
          { label: "Алдаа", value: "Алдаа" },
          { label: "Гомдол", value: "Гомдол" },
          { label: "Санал", value: "Санал" },
          { label: "Талархал", value: "Талархал" },
        ],
      },
    },
    {
      label: "Зураг",
      name: "image",
      type: "file",
      // rules: [{ required: true, message: "Зураг оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Чухалын зэрэг",
      name: "priority",
      className: "col-span-12 md:col-span-6 mb-0",
      type: "select",
      inputProps: {
        className: "w-full",
        options: [
          { label: "Энгийн", value: "Энгийн" },
          { label: "Чухал", value: "Чухал" },
        ],
      },
    },
    {
      label: "Санал",
      className: "col-span-12 md:col-span-12 mb-0",
      name: "feedback",
      type: "textarea",
      rules: [{ required: true, message: "Саналаа бичнэ үү!" }],
      inputComponent: Input.TextArea,
    },
  ];
   
};
