import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Upload, message } from "antd";
import { EditOutlined, ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import fields from "./fields"; // Assuming fields configuration is correctly defined
import { AuthContext } from "contexts";
import { Form as AntForm } from "antd";
import { saveAs } from 'file-saver';

const { Dragger } = Upload;

function Document() {
  const authContext = useContext(AuthContext);
  const { action } = authContext;
  const [data, setData] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [form] = AntForm.useForm(); // Ant Design form hook
  const [modal, contextHolder] = Modal.useModal();
  const [searchLoading, setSearchLoading] = useState(false);
  const [filterForm] = AntForm.useForm();

  useEffect(() => {
    action.handleSidebarKey("document");
    fetchData();
    // fetchDocumentTypes();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: "/document/list",

    })
      .then((res) => {
   
        setData(res.data);
      })
      .catch((err) => {
        message.error("Failed to fetch documents.");
      })
      .finally(() => setLoading(false));
  };
  
  // const fetchDocumentTypes = () => {
  //   setLoading(true);
  //   instance({
  //     method: "get",
  //     url: "/documentTypes",
  //   })
  //     .then((res) => {
  //       setDocumentTypes(
  //         res.data.map((item) => ({
  //           value: item.id,
  //           label: item.name,
  //         }))
  //       );
  //     })
  //     .catch((err) => {
  //       message.error("Failed to fetch document types.");
  //     })
  //     .finally(() => setLoading(false));
  // };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "id",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "Name",
    },
    {
      title: "File",
      dataIndex: "fileUrl",
      key: "file",
    },
    // {
    //   title: "Note",
    //   dataIndex: "note",
    //   key: "note",
    // },
    {
      title: "Action",
      key: "download",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleDownload(row)}>
          <EditOutlined /> Татаж авах
        </Button>
      ),
    },
    //  {
    //   title: "",
    //   dataIndex: "action",
    //   key: "action",
    //   width: 80,
    //   render: (text, row) => (
    //     <div className="flex items-center gap-5">
    //       <Button size="small" onClick={() => handleEdit(row)}>
    //         <div className="flex items-center gap-2">
    //           <EditOutlined /> Засах
    //         </div>
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

   
    formData.set("file", values.fileUrl?.file ? values.fileUrl?.file : "");
  
    try {
      let response;
      if (editData?.id) {
        formData.append("id", editData.id);
        response = await instance.put("/document", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } else {
        response = await instance.post("/document/create", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
      handleCloseModal();
      fetchData();
    } catch (error) {
      message.error("Failed to submit document.");
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setIsEdit(false);
    setEditData(null);
    setFileList([]);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setIsEdit(true);
    setFileList([]);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleDownload = async (row) => {
    const url = `${process.env.REACT_APP_CDN_URL}${row?.fileUrl}`;
    try {
      const response = await fetch(url);
      const blob = await response.blob();
  
      // Extract the file name and extension from the URL
      const urlParts = row?.fileUrl.split('/');
      const fileNameWithExtension = urlParts[urlParts.length - 1];
      saveAs(blob, fileNameWithExtension);
    } catch (error) {
      message.error("Failed to download the file.");
    }
  };
  
  
  const searchFields = [
    {
      label: "Нэр",
      name: "name",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
  ];
  const handleFilterSubmit = (values) => {
    setSearchLoading(true);
    
    instance({
      method: "get",
      url: `/document?pageindex=0&pagesize=${20}&name=${
        values.name ? values.name : ""
      }`,
    })
      .then((res) => {
        setData(res.data.document);
      })
      .catch((err) => {})
      .then(() => setSearchLoading(false));
  };

  return (
    <div className="flex flex-col px-4 pb-4">
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow mb-5 w-1/2">
        <div className="text-xl leading-none mb-3">Хайлт</div>
        <Form
          form={filterForm}
          fields={searchFields}
          length={data.length}
          layout="vertical"
          onFinish={handleFilterSubmit}
          className="gap-5"
        >
          <div className="col-span-12 flex justify-end gap-2 mt-4">
            <Button
              className="flex items-center"
              size="small"
              type="default"
              htmlType="submit"
              onClick={() => filterForm.submit()}
              loading={searchLoading}
            >
              <SearchOutlined />
              Хайх
            </Button>
            <Button
              className="flex items-center"
              size="small"
              type="default"
              onClick={() => filterForm.resetFields()}
            >
              <ClearOutlined />
              Цэвэрлэх
            </Button>
          </div>
        </Form>
      </div>
      <MainTable
        dataTable={data}
        columns={columns}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={true}
        title={"Бичиг баримт"}
        handleAdd={handleAdd}
        handleRefresh={fetchData}
      />
      <Modal
        title={editData ? "Бичиг баримт мэдээлэл засах" : "Бичиг баримт нэмэх"}
        visible={open}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields({ documentTypes })}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={editData}
          className="gap-4"
        />
       
      </Modal>
      {contextHolder}
    </div>
  );
}

export default Document;
